import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationPermissionsService {
  private permissionStatus$ = new BehaviorSubject<PermissionState>('prompt');
  private location$ = new BehaviorSubject<{ latitude: number; longitude: number } | null>(null);

  constructor() {
    this.initializePermissions();
  }

  private async initializePermissions(): Promise<void> {
    if ('permissions' in navigator) {
      try {
        const permission = await navigator.permissions.query({ name: 'geolocation' });
        this.permissionStatus$.next(permission.state);

        permission.onchange = () => {
          this.permissionStatus$.next(permission.state);
        };
      } catch (error) {
        console.error('Error initializing permissions:', error);
      }
    }
  }

  getPermissionStatus() {
    return this.permissionStatus$.asObservable();
  }

  getLocation() {
    return this.location$.asObservable();
  }

  requestLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = { latitude: position.coords.latitude, longitude: position.coords.longitude };
          this.location$.next(location);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              this.permissionStatus$.next('denied');
              break;
            case error.POSITION_UNAVAILABLE:
            case error.TIMEOUT:
              console.error('Unable to retrieve location:', error.message);
              break;
            default:
              console.error('An unknown error occurred.');
          }
        }
      );
    } else {
      console.error('Geolocation is not supported by your browser.');
    }
  }
}
